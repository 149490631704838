import React, { useState } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { putMessage } from '../store/actions/message'
import { fetchProfile } from '../store/actions/info'
import { setAmount, submitBuy, clear, checkCurrentTransaction } from '../store/actions/payment';
import PaymentModal from './PaymentModal';
import CurrentTransactionModal from "./CurrentTransactionModal";

const DEFAULT_PURCHASE_BUTTONS = [
    { title: '42 ELON — $21 USD', amount: 42},
    { title: '420 ELON — $210 USD', amount: 420},
    { title: '4206 ELON — $2,103 USD', amount: 4206},
    { title: '42069 ELON — $21,035 USD', amount: 42069},
]
const MAX_PURCHASE_QTY = 100000;
export default function PurchaseButtons(props) {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [customAmount, setCustomAmount] = useState(10);
    const [isCustomAmount, setIsCustomAmount] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [currentTransactionModal, setShowCurrentTransactionModal] = useState(false);

    const currentAmount = useSelector(state => state.payment.amount);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const isLoading = useSelector(state => state.payment.isLoading);
    const secretKey = useSelector(state => state.payment.secretKey);
    const loadingInfo = useSelector(state => state.info.isLoading);
    const minQty = useSelector(state => state.info.minQty);
    const price = useSelector(state => state.info.price);
    const currentTransactionLink = useSelector(state => state.payment.currentTransaction.meta.link);
    const maxQty = MAX_PURCHASE_QTY;

    const handleAmountClick = (amount) => {
        dispatch(setAmount(amount))
        setIsCustomAmount(false);
    };
    const handleCustomValueChange = (e) => {
        setCustomAmount(e.target.value);
    }
    const handleSubmitCustom = () => {
        if(customAmount < minQty || customAmount > maxQty){
            dispatch(putMessage({
                body: `Minimal amount of ELONs is ${minQty} and maximum amount is ${maxQty}`
            }))
        } else {
            setIsCustomAmount(true);
            dispatch(setAmount(customAmount))
            setShowModal(false)
        }

    }
    const handleSubmitBuy = () => {
        if(currentAmount > 0 ){
            dispatch(checkCurrentTransaction()).then(canCreateNewTransaction => {
                if(canCreateNewTransaction){
                    setShowPaymentModal(true);
                } else {
                    setShowCurrentTransactionModal(true);
                }
            });
        }

    }
    const handleSuccessPay = () => {
        dispatch(clear())
        setTimeout(() => dispatch(fetchProfile()), 3000)
    }

    return (
        <>
            {DEFAULT_PURCHASE_BUTTONS.map((item, index) => <Button 
                                            key={index} 
                                            disabled={loadingInfo}
                                            variant={currentAmount === item.amount ? 'light' : 'outline-light'} 
                                            onClick={() => handleAmountClick(item.amount)}
                                            block>
                                                {item.title}
                                          </Button>)}
            {
                isCustomAmount ?
                <Button variant="light" block onClick={() => setShowModal(true)}>{currentAmount} ELON</Button>
                : <Button variant="outline-light" block onClick={() => setShowModal(true)}>custom amount</Button>
            }
            <Button variant="light" block onClick={handleSubmitBuy} disabled={isLoading || loadingInfo }>
                {isLoading || loadingInfo ? <Spinner animation="border" size="sm" role="status"></Spinner> : 'Buy Now'}
            </Button>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header className="d-flex justify-content-end align-items-center">
                    <div onClick={() => setShowModal(false)} className="px-2 close-btn">&times;</div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="amount">
                        <Form.Label>Enter Custom Coin Amount</Form.Label>
                        <Form.Control onKeyPress={(e) => {
                            if(e.key === 'Enter') {
                                handleSubmitCustom()
                            }
                        }} min={minQty} max={maxQty} value={customAmount} onChange={handleCustomValueChange} className="w-100" size="sm" type="number" />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" size="sm" onClick={handleSubmitCustom}>Submit</Button>
                    <Button variant="outlined-secondary"  size="sm" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <PaymentModal handleSuccessPay={handleSuccessPay} showModal={showPaymentModal} hideModal={() => setShowPaymentModal(false)} secret={secretKey}/>
            <CurrentTransactionModal link={currentTransactionLink} showModal={currentTransactionModal} hideModal={() => setShowCurrentTransactionModal(false)}></CurrentTransactionModal>
        </>
    )
}