import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import divideThousands from '../utils/divideThousands';
function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

const INTERVAL_IN_MILLISECONDS = 4000;
const RAND_DICREMENT_MAX_VALUE = 10;

export default function Counter(props) {
    const { startValue } = props;
    const [count, setCount] = useState(startValue);
    const [randValue, setRandValue] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const randVal = getRandomInt(RAND_DICREMENT_MAX_VALUE);
    //         setCount(count => count - randVal);
    //         setRandValue((randValue) => randVal);
    //     }, INTERVAL_IN_MILLISECONDS);
    //     return () => clearInterval(interval);
    //   }, [count, randValue]);

    return (
        <span className="counter">
            {/* <CountUp start={count + randValue} end={count} separator={','} /> */}
            {divideThousands(count)}
        </span>
    );
}