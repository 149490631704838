export default function checkCoinbaseStatus(timeline, statuses){
    let result = false;

    timeline.forEach(item => {
        if(statuses.includes(item.status)){
            result = true;
        }
    })

    return result;
}