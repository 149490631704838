import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.GATSBY_COINBASE_API_URL,
})

export default {
    charge(code){
        return axiosInstance.get(`/charges/${code}`);
    }
}