import action_types from '../actionTypes';
import api from '../../api';
import coinbaseApi from "../../api/coinbase";
import _ from 'lodash';
import store from '../index';
import {putMessage} from './message'
import checkCoinbaseStatus from "../../utils/checkCoinbaseStatus";

const STRIPE_FEE = .029;

export function setLoading(flag) {
    return {
        type: action_types.PAYMENT_SET_LOADING,
        flag
    }
}

export function setAmount(amount) {
    const price = _.get(store.getState(), 'info.price', 0)
    const formattedPrice = price ? (parseFloat(price) / 100).toFixed(2) : null; 
    const subtotal =  parseFloat(formattedPrice) * parseInt(amount);
    const fee = ((subtotal * STRIPE_FEE) + 0.3).toFixed(2); 
    const total = parseFloat(subtotal) /*+ parseFloat(fee) */;
    return {
        type: action_types.PAYMENT_SET_AMOUNT,
        data: {
            subtotal,
            amount,
            total,
            fee,
            stripeTotal: total + parseFloat(fee)
        }
        
    }
}

export function setPaymentData(data) {
    return {
        type: action_types.PAYMENT_SET_DATA,
        data
    }
}

export function setCurrentTransactionData(data){
    return {
        type: action_types.PAYMENT_SET_CURRENT_TRANSACTION_DATA,
        data
    }
}

export function clear() {
    return {
        type: action_types.PAYMENT_CLEAR,
    }
}

function checkCurrentTransactionStatus(transaction){
    return coinbaseApi.charge(transaction.code).then((result) => {
        return _.get(result, 'data.data', {});
    });
}

export function checkCurrentTransaction(){
    return dispatch => {
        dispatch(setLoading(true));
        return new Promise(resolve => {
            // Get pending transaction from the local storage...
            let storedTransaction = window.localStorage.getItem('current_transaction') || null;
            if (storedTransaction) {
                storedTransaction = JSON.parse(storedTransaction);

                return checkCurrentTransactionStatus(storedTransaction).then((result) => {
                    const isCompleted = checkCoinbaseStatus(result.timeline, ['COMPLETED', 'UNRESOLVED', 'EXPIRED', 'RESOLVED', 'CANCELED', 'REFUND PENDING', 'REFUNDED']);

                    if(!isCompleted){
                        const data = {
                            ...result,
                            meta: {
                                link: `${process.env.GATSBY_COINBASE_HOST_URL}receipts/${result.code}`
                            }
                        }
                        dispatch(setCurrentTransactionData(data))
                        resolve(false);
                    } else {
                        localStorage.removeItem('current_transaction')
                        resolve(true);
                    }

                    dispatch(setLoading(false));
                })
            }
            dispatch(setLoading(false));
            resolve(true)
        })
    }
}

export function submitBuy() {
    return dispatch => {
        dispatch(setLoading(true));
        const method =  _.get(store.getState(), 'payment.method', null);
        const params = {
            quantity: _.get(store.getState(), 'payment.amount', 0),
            method
        }
        if(params.quantity === 0) return;

        return api.buy(params).then(res => {
            dispatch(setPaymentData(res.data))
            dispatch(setLoading(false));
            let result = {
                status: res.status,
            };

            if(method == 'stripe'){
                result['payload'] = res.data.client_secret
            }
            if(method == 'coinbase'){
                // And put it to the local storage...
                window.localStorage.setItem('current_transaction', JSON.stringify(res.data));

                // @todo cleanup the local storage on success!
            }


            return result; 
        }).catch(e => {
            dispatch(putMessage({
                body: e.response.data.error
            }))
            dispatch(setLoading(false));
        });
    }
}

export function setPaymentMethod(method){
    return {
        type: action_types.PAYMENT_SET_TYPE,
        method
    }
}