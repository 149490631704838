import React from 'react';


function ProgressBlock(props){
    return (
        <div className="milestones__block">
            <ProgressTitle title={props.title} now={props.now}/>
            <ProgressDivider />
            <ProgressCount count={props.count} />
        </div>
    )
}

function ProgressTitle({ title, now }){
    return (
        <div className="milestones__title">
            { !!now ? <b>Now</b> : '\u00A0' } 
            <div>{title}</div>
        </div>
    )
}

function ProgressCount({ count }){
    return (
        <div className="milestones__count">
            <div>{ count }</div>
            <b>ELON</b>
        </div>
    )
}

function ProgressDivider(){
    return (
        <div className="milestones__divider"></div>
    )
}

export default function Milestones(props){
    const data = [...props.data];
    return (
        <div className="milestones">
            {data.map((item, index) => <ProgressBlock key={index} title={item.title} count={item.count} now={item.now} />)}
        </div>
    )
}