import React from "react";
import {Button, Form, Modal} from "react-bootstrap";

export default function CurrentTransactionModal(props){
    return (
        <Modal show={props.showModal} onHide={props.hideModal} centered>
            <Modal.Header className="d-flex justify-content-end align-items-center">
                <div onClick={props.hideModal} className="px-2 close-btn">&times;</div>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center text-light">
                    You have a purchase pending. You can view the progress <a target="_blank" href={props.link}>(here)</a>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outlined-secondary"  size="sm" onClick={props.hideModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}