import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import divideThousands from '../utils/divideThousands';



export default function PaymentDetail(props) {
    
    const price = useSelector(state => state.info.price);
    const currentAmount = useSelector(state => state.payment.amount);
    const subtotal = useSelector(state => state.payment.subtotal);
    const fee = useSelector(state => state.payment.fee);
    const total = useSelector(state => state.payment.total);
    
    const formattedPrice = price ? (parseFloat(price) / 100).toFixed(2) : null; 
    const totalPrice = props.isStripeMethod ? parseFloat(total) + parseFloat(fee) : total;
    return (
        <div className="payment-details">
            <Row>
                <Col xs={6}>{currentAmount} ELON <small>${formattedPrice} / ELON</small></Col>
                <Col xs={6} className="text-right">${divideThousands(subtotal)} USD</Col>
                {
                    props.isStripeMethod && 
                    <>
                        <Col xs={6}>CARD FEE</Col>
                        <Col xs={6} className="text-right">${fee} USD</Col> 
                    </>
                }


                <Col xs={6}><b>TOTAL</b></Col>
                <Col xs={6} className="text-right">${divideThousands(totalPrice)} USD</Col>
            </Row>
        </div>

    );
}