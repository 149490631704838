import React, { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {Button, Spinner, Form, Input} from 'react-bootstrap';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#1C1E39',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {color: '#fce883'},
      '::placeholder': {color: '#87bbfd'},
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

export default function CheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState();
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [billingDetails, setBillingDetails] = useState({
    city: '',
    line1: '',
    state: ''
  });
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };


  const pay = async (ev, secret) => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          address: {...billingDetails}
        }
      }
    });

    if (payload.error) {
      props.handleError(payload.error.message);
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      props.handleSuccessPay({
        description: payload.paymentIntent.description
      });
    }
  }

  const handleSubmit = (ev, secret) => {
    props.handleSubmit((secret) => pay(ev, secret));
  };
  const handleAddressFormInput = (key, value) => {
    let details = {...billingDetails};
    details[key] = value;
    setBillingDetails(details);
    let isEmpty = false;
    for (const objKey in details) {
      if(details[objKey].length == 0){
        isEmpty = true;
      }
    }
    setIsFormEmpty(isEmpty)
  }

  return (
    <form id="payment-form"  className="text-center checkout-form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup py-2">
        <div className="FormRow">
          <Form.Control 
            onChange={(e) => handleAddressFormInput('line1',e.target.value)}
            value={billingDetails.line1} 
            placeholder="Address" 
            type="text" 
            className="p-0 w-100" />
        </div>
        <hr className="my-1 w-100"/>
        <div className="d-flex flex-wrap flex-md-nowrap">
          <div className="FormRow w-100 w-md-50">
            <Form.Control
                onChange={(e) => handleAddressFormInput('city',e.target.value)}
                value={billingDetails.city}
                placeholder="City"
                type="text"
                className="p-0 "  />
          </div>
          <hr className="my-1 d-block d-md-none w-100"/>
          <div className="FormRow w-100 w-md-50">
            <Form.Control
                onChange={(e) => handleAddressFormInput('state',e.target.value)}
                value={billingDetails.state}
                placeholder="State"
                type="text"
                className="p-0 " />
          </div>
        </div>
      </fieldset>
      <fieldset className="FormGroup">
        <div className="FormRow">
          <CardElement options={CARD_OPTIONS} onChange={handleChange} />
        </div>
      </fieldset>
      
        <div className="ErrorMessage" role="alert" style={
          {opacity: error ? 1 : 0 }
        }>
          {error}
        </div>
      {props.children}
      <Button
        onClick={handleSubmit}
        variant="primary"
        block
        disabled={processing || disabled || succeeded || props.btnDisabled || props.isLoading || isFormEmpty}
        id="submit"
      >
          {processing || props.isLoading ? (
            <Spinner animation="border" size="sm" role="status"></Spinner>
          ) : (
            "Pay" + (props.total ? ' $' + props.total : '')
          )}
      </Button>
    </form>
  );
}