import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./Payment/CheckoutForm";
import { Modal, Button, Collapse, Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import PaymentDetails from './PaymentDetails';
import AuthForm from './AuthForm';
import { useSelector, useDispatch } from 'react-redux';
import { signUp } from '../store/actions/auth'
import { setPaymentMethod, submitBuy } from '../store/actions/payment'
import { putMessage } from "../store/actions/message";
import { Link } from 'gatsby'
import CoinbaseButton from './Payment/CoinbaseButton';

const promise = loadStripe(process.env.GATSBY_STRIPE_API_KEY);

export default function PaymentModal(props) {
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false);
  const [paymentDetail, setPaymentDetail] = useState();
  const [isDelayLoading, setIsDelayLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const total = useSelector(state => state.payment.total);
  const stripeTotal =useSelector(state => state.payment.stripeTotal);
  const userEmail = useSelector(state => state.info.email);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isAuthLoading = useSelector(state => state.auth.isLoading);
  const isPaymentLoading = useSelector(state => state.payment.isLoading);
  const isEmptyForm = useSelector(state => state.auth.isEmptyForm);
  const coinBaseCheckoutId = useSelector(state => state.payment.checkoutId);
  const paymentMethod = useSelector(state => state.payment.method);


  const handleTermsChange = () => {
    setTerms(!terms)
  }
  const handleSuccessPay = (detail) => {
    setIsSuccess(true);
    setPaymentDetail(detail)
    props.handleSuccessPay();
  }
  const handleHideModal = () => {
    setTerms(false);
    setIsSuccess(false);
    props.hideModal();
  }
  const handleError = (error) => {
    dispatch(putMessage({
      body: error
    }))
  }

  const attemptBuy = (pay) => {
    dispatch(submitBuy()).then((res) => {
          if(res.status == 201){
            pay(res.payload)
          } 
    })
  }
  const handleSubmit = (pay, method) => {
    dispatch(setPaymentMethod(method));
    if(isAuthenticated){
      attemptBuy(pay);
    } else {
      dispatch(signUp()).then(status => {
        if(status == 'ok'){
          setIsDelayLoading(true);
          setTimeout(() => {
            setIsDelayLoading(false)
            attemptBuy(pay)
          }, 2000);
        }
      });
    }
  }

  const btnDisabled = (!isAuthenticated && isEmptyForm) || !terms;
  const isFormLoading = isAuthLoading || isPaymentLoading || isDelayLoading;
  const isStripeMethod = paymentMethod === 'stripe';
  const isCoinbaseMethod = paymentMethod === 'coinbase';
  return (
    <Modal show={props.showModal} onHide={handleHideModal} centered className="payment-modal" size='lg'>
        <Modal.Header className="d-flex justify-content-end align-items-center">
            <div onClick={handleHideModal} className="px-2 close-btn">&times;</div>
        </Modal.Header>
        <Modal.Body>
          { isSuccess ?
            <div className="text-center">
              <div className="text-center ">Payment Successful!</div>
              <p>{paymentDetail ? paymentDetail.description : null}</p>
              <Button  variant="primary" block onClick={handleHideModal}>
                Close
              </Button>
            </div>
          : 
          <>
            {isAuthenticated && <h3 className="payment-modal__title text-center">Hello {userEmail}</h3>}
            <Collapse in={!isAuthenticated}>
              <div>
                <h3 className="payment-modal__title">Account Details</h3>
                <AuthForm></AuthForm>
              </div>
            </Collapse>
            <h3 className="payment-modal__title">Payment details</h3>
            <PaymentDetails isStripeMethod={isStripeMethod} />
            <ButtonGroup toggle className="w-100">
              <ToggleButton
                type="radio"
                variant="primary"
                checked={paymentMethod == 'coinbase'}
                value="coinbase"
                onChange={() => dispatch(setPaymentMethod('coinbase'))}
              >
                Pay with Crypto
              </ToggleButton>
              <ToggleButton
                type="radio"
                variant="primary"
                checked={paymentMethod == 'stripe'}
                value="stripe"
                onChange={() => dispatch(setPaymentMethod('stripe'))}
              >
                Pay with Card
              </ToggleButton>
            </ButtonGroup>
            <Collapse in={isStripeMethod}>
              <div>
                <Elements stripe={promise}>
                    <CheckoutForm 
                      total={stripeTotal} 
                      secret={props.secret} 
                      centered 
                      isLoading={isFormLoading}
                      btnDisabled={btnDisabled}
                      handleSubmit={(pay) => handleSubmit(pay, 'stripe')}
                      handleError={handleError}
                      handleSuccessPay={(detail) => handleSuccessPay(detail)}>
                        <Form.Check 
                          type='checkbox'
                          className="mt-4"
                          checked={terms}
                          id={`terms`}
                        >
                          <Form.Check.Input onChange={handleTermsChange} type="checkbox" />
                          <Form.Check.Label>&nbsp;AGREE TO THE <Link to="/terms-of-use">TERMS</Link></Form.Check.Label>
                        </Form.Check>
                    </CheckoutForm>
                </Elements>
              </div>
            </Collapse> 
            <Collapse in={isCoinbaseMethod}>
              <div>
                  <Form.Check 
                    type='checkbox'
                    className="mt-4 text-center"
                    checked={terms}
                    id={`terms`}
                  >
                    <Form.Check.Input onChange={handleTermsChange} type="checkbox" />
                    <Form.Check.Label>&nbsp;AGREE TO THE <Link to="/terms-of-use">TERMS</Link></Form.Check.Label>
                  </Form.Check>
                  <CoinbaseButton
                    checkoutId={coinBaseCheckoutId}
                    btnDisabled={btnDisabled}
                    handleError={handleError}
                    isLoading={isFormLoading}
                    handleCloseModal={handleHideModal}
                    handleSuccessPay={() => handleSuccessPay({description: ''})}
                    handleSubmit={(pay) => handleSubmit(pay, 'coinbase')}
                  />
              </div>
            </Collapse>
          </>
          }

        </Modal.Body>
    </Modal>
  );
}