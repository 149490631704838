import React, { useRef } from 'react';
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import { Button, Spinner} from 'react-bootstrap';


export default function CoinbaseButton(props) {
    const buttonEl = useRef();

    const pay = () => {
        buttonEl.current.handleButtonClick();
    }
    const handleSubmit = () => {
        props.handleSubmit(pay)
    }

    return (
        <>
            <CoinbaseCommerceButton 
                onChargeFailure={() => props.handleError('Failed to pay with coinbase')}
                onChargeSuccess={props.handleSuccessPay} 
                ref={buttonEl} 
                styled
                onModalClosed={props.handleCloseModal}
                chargeId={props.checkoutId}/>
            <Button
                disabled={props.btnDisabled || props.isLoading} 
                block 
                onClick={handleSubmit}>
                    {props.isLoading ? (
                        <Spinner animation="border" size="sm" role="status"></Spinner>
                    ) : (
                        "Pay With Coinbase"
                    )}
            </Button>
        </>
    )
}