import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';

const PURCHASE_BUTTONS = [
    { title: '5 ELON', variant: 'outline-light'},
    { title: '10 ELON', variant: 'outline-light'},
    { title: '25 ELON', variant: 'outline-light'},
    { title: '100 ELON', variant: 'outline-light'},
    { title: 'custom amount', variant: 'outline-light'},
    { title: 'Buy Now', variant: 'light'},
]

function NavButton(props){
    return (
        <Container fluid className="hero__nav">
            <Row className="justify-content-md-end">
                <Col sm={12} className="text-right">
                    <Button  variant="link-light" onClick={props.onClick}>
                        {props.title}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

function Title({ children }) {
    return (
        <h1 className="hero__title">
            {children}
        </h1>
    )
}

function SubTitle({ children, light }) {
    let className = 'hero__subtitle';
    if(light){
        className += ' hero__subtitle-light'
    }
    return (
        <h2 className={className}>
            {children}
        </h2>
    )
}

function Desc({ children }) {
    return (
        <div className="hero__desc">
            {children}
        </div>
    )
}

function Buttons({ children }) {
    return (
        <div className="hero__buttons">
            {children}
        </div>
    )
}

export default function Hero(props){
    let cssClass = 'hero';
    if(props.empty){
        cssClass += ' hero-empty'
    }
    return <div className={cssClass}>
                <div className="hero__body">
                    {props.children}
                </div>
                <div className="ellipse-bg"></div>
           </div>;
}

Hero.NavButton = NavButton;
Hero.Title = Title;
Hero.SubTitle = SubTitle;
Hero.Desc = Desc;
Hero.Buttons = Buttons;