import React from 'react';

export default function Section(props) {
    let className = "section ";

    if(props.right){
        className += "text-right"
    }
    if(props.center){
        className += "text-center"
    }
    return (
        <section className={className}>
            <h3 className="section__title">{props.title}</h3>
            <p className="section__body">{props.body}</p>
        </section>
    );
}